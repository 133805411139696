import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'epochDate'
})
export class EpochToDate implements PipeTransform {
  transform(value: number): any {
    return moment.unix(value).format('MMMM DD, YYYY');
  }
}
