// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://lfx-bff.dev.platform.linuxfoundation.org/api/faraday',
  AUTH_CLIENT_ID: '8XVG4ieGwxtbTuaDz0iQQ2xA9dfgbtba',
  AUTH_DOMAIN: 'linuxfoundation-dev.auth0.com',
  AUTH_REDIRECT_URL: 'https://learner.nextgen.dev.platform.linuxfoundation.org/auth',
  SOCKET_URL: 'https://lfx-bff.dev.platform.linuxfoundation.org',
  RAMP_RECEIPT_TOKEN: 'rr_test_d407b9a2-9796-41bf-af8f-0d2bc7bf177f',
  EXAM_URL: 'https://www.examslocal.com/linuxfoundation',
  ORIGIN: 'https://learner.nextgen.dev.platform.linuxfoundation.org',
  TAO_URL: 'https://taois-dev1.lfc-np.com',
  TI_HOOKS: 'https://api-gw.dev.platform.linuxfoundation.org/ti-hooks-service/v1',
  TI_URL: 'https://thoughindustries.dev.platform.linuxfoundation.org/learn/dashboard',
  ENV: 'dev',
  EXTREME_API_KEY: 'bHZqboO0fUfrrHfBdfTs'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
