import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
  @Input() name = 'autocomplete';
  @Input() forceSelect = false;
  @Input() value: string;
  @Input() items: string[] = [];
  @Input() required = false;
  @Output() valueChange = new EventEmitter<string>();
  private didSelect = false;

  constructor() {}

  ngOnInit() {}

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2 ? [] : this.items.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
      )
    );

  selectItem() {
    this.didSelect = true;
  }

  onChange() {
    this.didSelect = false;
    this.valueChange.emit(this.value);
  }

  // Forcing validation since ng typeahead "editable" property doesn't work
  onBlur() {
    if (this.forceSelect && !this.didSelect) {
      // check if the text is part of the selection item
      const selection = this.value || '';
      if (!this.items.find((i) => i.toLocaleLowerCase() === selection.toLocaleLowerCase())) {
        this.value = '';

        this.valueChange.emit(this.value);
      }
    }
  }
}
