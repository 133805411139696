import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line: no-forward-ref
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input() items: string[] = [];
  @Input() placeholder = '';
  @Output() changed: EventEmitter<any> = new EventEmitter();
  _selectedItem = '';
  isValueSelected = false;
  propagateChange = (_: any) => {};

  constructor() {}

  ngOnInit() {
    if (this.placeholder === '') {
      this.placeholder = 'Select';
    }
    this.selectedItem = this.placeholder;
  }

  get selectedItem() {
    return this._selectedItem;
  }

  set selectedItem(val) {
    this._selectedItem = val;
    this.propagateChange(this._selectedItem);
    this.changed.emit(this._selectedItem);
    this.isValueSelected = true;
  }

  onSelected(item: string) {
    this.selectedItem = item;
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.selectedItem = value;
      this.isValueSelected = true;
      if (value === '') {
        this.selectedItem = this.placeholder;
        this.isValueSelected = false;
      }
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
