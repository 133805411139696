import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentPipe'
})
export class MomentPipe implements PipeTransform {
  transform(value: Date | moment.Moment | number | string | null, ...args: any[]): any {
    const [format] = args;

    return value && moment(value).isValid() ? moment(value).format(format) : '';
  }
}
