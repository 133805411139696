import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyProfileComponent } from './modules/my-profile/my-profile.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: './modules/home/home.module#HomeModule',
    data: {
      title: 'Learner UI'
    }
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'My Profile'
    }
  },
  {
    path: 'receipt-management',
    loadChildren: './modules/receipt-management/receipt-management.module#ReceiptManagementModule',
    data: {
      title: 'Learner UI'
    }
  },
  {
    path: 'user-location',
    loadChildren: './modules/user-location/user-location.module#UserLocationModule',
    data: {
      title: 'Learner UI'
    }
  },
  {
    path: 'tao',
    loadChildren: './modules/tao-integration/tao-integration.module#TaoIntegrationModule',
    data: {
        title: 'TAO'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
