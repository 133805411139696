// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import * as auth0 from 'auth0-js';
import { CookieService } from 'ngx-cookie-service';

import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
  expiresAt: any;
  accessToken: string;
  authenticated: boolean;
  isNotVerified = false;
  refreshSubscription: any;
  user: any;
  initialAuthCheck = new ReplaySubject<auth0.Auth0DecodedHash | null>(1);

  auth0 = new auth0.WebAuth({
    clientID: environment.AUTH_CLIENT_ID,
    domain: environment.AUTH_DOMAIN,
    responseType: 'token id_token code',
    redirectUri: environment.AUTH_REDIRECT_URL,
    responseMode: 'fragment',
    scope: 'openid profile email'
  });

  private _isAuthChecked = false;

  constructor(public router: Router, private cookieService: CookieService) {
    this.getAccessToken();
  }

  get isLoggedIn(): boolean {
    // Check if current date is before token
    // expiration and user is signed in locally
    this.expiresAt = this.cookieService.get('expiresAt');
    return Date.now() < this.expiresAt;
  }

  isLoggedInPromise(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const authResult = await this.checkSessionPromise().catch((err) => {
        if (err && err.code === 'interaction_required') {
          // if the error code is "interaction_required" then we should resolve this to "true"
          // and set the "isNotVerified"(add to this service) flag as true
          this.isNotVerified = true;
          resolve(true);
        } else {
          console.log(err);
          // still using the resolve here instead of reject because we want the user to not have access if the error is
          // not "interaction_required"
          resolve(false);
        }
      });
      if (authResult && authResult.accessToken) {
        this.getUserInfo(authResult);

        const profile = await this.userInfoPromise(authResult.accessToken);
        if (profile) {
          this.isAuthChecked = true;
          this.user = profile;
          this._setSession(authResult);
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  }

  get isAuthChecked(): boolean {
    // Checking if auth override exists in localcStorage
    // This is used so that tests can still use the silent login by setting this to true
    return this.isAuthOverride ? true : this._isAuthChecked;
  }

  set isAuthChecked(value: boolean) {
    this._isAuthChecked = value;
  }

  get isAuthOverride(): boolean {
    const isOverride = this.cookieService.get('authOverride'); // localStorage.getItem('authOverride');
    return isOverride && isOverride === 'true';
  }

  login(state: string = '/'): void {
    const options: any = {
      state: state,
      prompt: 'none'
    };
    // this.cookieService.deleteAll();
    this.auth0.authorize(options);
  }

  handleLoginCallback(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken) {
          this._setSession(authResult);
          resolve(authResult);
        } else if (err) {
          reject(err.error);
        }
      });
    });
  }

  async getAccessToken() {
    // skipping the auth check if we are on testing mode
    if (!this.isAuthOverride) {
      this.auth0.checkSession({}, (_err, authResult) => {
        // learner to a verify notification page
        if (authResult && authResult.accessToken) {
          this.getUserInfo(authResult);
        }
      });
    } else {
      console.log('skipping login check');
    }
  }

  unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  getUserInfo(authResult) {
    // Use access token to retrieve user's profile and set session
    if (!this.user) {
      this.auth0.client.userInfo(authResult.accessToken, (_err, profile) => {
        if (profile) {
          this.user = profile;
          this._setSession(authResult);
        }
      });
    }
  }

  // wrapping the auth0.client.userInfo call in a Promise so we can make them synchronous
  private userInfoPromise(accessToken): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(accessToken, (_err, profile) => {
        if (profile) {
          resolve(profile);
        } else {
          reject(null);
        }
      });
    });
  }

  private checkSessionPromise(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (_err, authResult) => {
        if (authResult && authResult.accessToken) {
          resolve(authResult);
        } else {
          // TODO: use the _err here
          reject(_err);
        }
      });
    });
  }

  private _setSession(authResult) {
    // Save authentication data and update login status subject
    this.authenticated = true;
    this.expiresAt = Date.now() + authResult.expiresIn * 1000;

    this.cookieService.set('idToken', authResult.idToken, this.expiresAt, '/', null, true, 'None');
    this.cookieService.set('expiresAt', this.expiresAt, this.expiresAt, '/', null, true, 'None');
  }
}
