import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  user: any;
  manageProfileLink: string;
  constructor(private authService: AuthService) {
    if (environment.ENV === 'prod') {
      this.manageProfileLink = `https://myprofile.lfx.linuxfoundation.org/`;
    } else {
      this.manageProfileLink = `https://myprofile.${environment.ENV}.platform.linuxfoundation.org`;
    }
  }

  ngOnInit() {
    this.user = this.authService.user;
  }
}
