import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared';
import { HttpService } from './core/services/http.service';
import { AuthService } from './core/services/auth.service';
import { MyProfileComponent } from './modules/my-profile/my-profile.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [AppComponent, MyProfileComponent],
  imports: [BrowserModule, AppRoutingModule, NgbModule, HttpClientModule, SharedModule],
  providers: [Title, HttpService, AuthService, AuthGuard, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {}
