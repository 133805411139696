import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { WindowRef } from './windowRef';
import { LoaderComponent } from './components/loader/loader.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { CardComponent } from './components/card/card.component';
import { SelectComponent } from './components/select/select.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { EpochToDate } from './pipes/epochToDate.pipe';
import { LfNavComponent } from './components/lf-nav/lf-nav.component';

@NgModule({
  imports: [CommonModule, NgbModule, ReactiveFormsModule, FormsModule, RouterModule.forChild([]), FontAwesomeModule, NgxDatatableModule],
  declarations: [
    CapitalizePipe,
    TrimPipe,
    MomentPipe,
    EpochToDate,
    LoaderComponent,
    CardComponent,
    SelectComponent,
    AutocompleteComponent,
    LfNavComponent
  ],
  exports: [
    CapitalizePipe,
    TrimPipe,
    MomentPipe,
    EpochToDate,
    LoaderComponent,
    CardComponent,
    FontAwesomeModule,
    SelectComponent,
    AutocompleteComponent,
    NgxDatatableModule,
    LfNavComponent
  ],
  providers: [WindowRef]
})
export class SharedModule {}
